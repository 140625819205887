 <template>
	<section class="section-wrapper">
        <base-heading :heading="`${prefix}.section_title`" :subheading="`${prefix}.section_subtitle`" v-animate="`slideRight`"/>
		<grid-template cols="4">
            <div class="box-item" v-for="(item, index) in imagesContent" :key="index" v-animate="`opacity${index}`">
                <cms-image
						class="gallery-img"
						:src="item.image_item"
						:base="{height: 350, width: 400}"
					/>
                <div class="box-content">
                    <base-font variant="small-heading" color="white" uppercase>{{item.image_title}}</base-font>
                    <base-font class="box-description" tag="span" size="sm" color="white">{{item.image_content}}</base-font>
                </div>
            </div>
        </grid-template>
        <base-container>
           <europe-map :prefix="prefix"/>
        </base-container>
	</section>
</template>

<script>
import BaseContainer from '../../components/atoms/BaseContainer/BaseContainer.vue'
import EuropeMap from '../../components/molecules/EuropeMap/EuropeMap.vue'
import BaseHeading from '../../components/molecules/headings/BaseHeading/BaseHeading.vue'
import GridTemplate from '../../templates/GridTemplate.vue'

export default {
	components: {
		GridTemplate,
		BaseHeading,
		BaseContainer,
		EuropeMap
	},
	props: {
		prefix: String,
		value: Object
	},
	computed: {
		imagesContent () {
			return this.value.repeater_content
		}
	}
}
</script>
<style lang="scss" scoped>
.section-wrapper {
	padding: 4rem 0;

	@include media-breakpoint-up(xl) {
		padding: 8rem 0;
        overflow: hidden;
	}
    ::v-deep .grid-wrapper {
        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-breakpoint-up(xxl) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    .box-item {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            background-color: $secondary;
            opacity: .7;
        }
        ::v-deep .base-image {
            position: relative;
            width: 100%;
            height: 300px;
            display: flex;
        }
        .box-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 100%;
            padding: 1rem;
            @include media-breakpoint-up(xxl) {
                padding: 1rem;
            }
            @include media-breakpoint-up(xl) {
                    ::v-deep .box-description {
                    height: 0;
                    opacity: 0;
                    @include transition();
                }
                padding: .5rem;
            }
        }
        @include media-breakpoint-up(xl) {
            &:hover {
                ::v-deep .box-description {
                    height: 100px;
                    opacity: 1;
                }
            }
        }
        @include media-breakpoint-up(xxl) {
            &:hover {
                ::v-deep .box-description {
                    height: 150px;
                    opacity: 1;
                }
            }
        }
    }
}
</style>
